<template>
  <div class="format">
    <div class="container">
      <a
        href="#"
        class="item"
        :class="{ active: format == item.type }"
        v-for="(item, index) in data"
        :key="index"
        @click.prevent="setFormat(item)"
        v-on:mouseover="hover = item.type"
        v-on:mouseleave="
          hover = false;
          hoverOut = true;
        "
      >
        <div class="icon">
          <object
            :key="item.type"
            :id="item.type"
            type="image/svg+xml"
            :data="getIcon(item)"
          ></object>
        </div>
        <div class="header">{{ item.header }}</div>
        <div class="description">{{ item.description }}</div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      hover: false,
      hoverOut: false,
      format: JSON.parse(JSON.stringify(this.$store.state.business.assistent))
        .type,
      data: [
        {
          icon: "feste_oeffnungszeiten",
          header: this.$t("format.parts.datepicker.title"),
          description: this.$t("format.parts.datepicker.info"),
          type: "datepicker"
        },
        {
          icon: "konkretes_event",
          header: this.$t("format.parts.eventpicker.title"),
          description: this.$t("format.parts.eventpicker.info"),
          type: "eventpicker"
        },
        {
          icon: "angebote_dienstleistungen",
          header: this.$t("format.parts.services.title"),
          description: this.$t("format.parts.services.info"),
          type: "services"
        }
      ]
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getIcon(item) {
      var r = "https://assistent.bookingflix.com/assets/img/" + item.icon;

      if (this.hover === item.type) {
        r += "_gruen.svg";
      } else if (!this.hover && this.format === item.type) {
        r += "_gruen.svg";
      } else if (this.hoverOut || this.format) {
        r += "_grau.svg";
      } else {
        r += "_grau_animated.svg";
      }
      return r;
    },
    setFormat(item) {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.type = item.type;

      this.$store.commit("business/prefetch", data);
      this.$store.commit("business/update", { type: item.type });

      if (item.type === "services") {
        this.$router.push({ name: "styling" });
      } else {
        this.$router.push({ name: "styling" });
      }
    }
  }
};
</script>
<style lang="sass" scoped>
object[type="image/svg+xml"]
  pointer-events: none
  width: 300px
  max-width: 50%
</style>
